import { Vue, Component } from 'vue-property-decorator'

@Component
export class CleanDescription extends Vue {
    descriptionToShow(description: string): string {
        const maxLength = 70
        const shortDescription = description.length > maxLength ? description.substring(0, maxLength) : description

        const tmp = document.createElement('DIV')
        tmp.innerHTML = shortDescription
        return tmp.textContent || tmp.innerText || ''
    }
}
