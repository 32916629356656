







import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class AlertBox extends Vue {
    @Prop({ type: String, default: 'warning' }) icon: string
    @Prop({ type: String, default: 'warning' }) severity: string
    @Prop({ type: String }) title: string
    @Prop({ type: String }) description: string
    @Prop({ type: Object }) link: object
}
