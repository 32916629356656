





































import { Component, Mixins } from 'vue-property-decorator'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { ModalStore, IModalParams } from '~/store/modal'
import { fetchAllServices } from '#/api/service'
import { CleanDescription } from '#/mixins/clean-description'
import AlertBoxSubscription from '#/components/AlertBoxSubscription.vue'
import { UserStore } from '#/store/user'

@Component({ components: { VLoadSpinner, AlertBoxSubscription } })
export default class ServicesPage extends Mixins(CleanDescription) {
    isLoading = true
    services: IService[] = []

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
    @UserStore.Getter('hasEliteSubscription') hasEliteSubscription: boolean

    async _fetchAllServices() {
        this.isLoading = true
        try {
            this.services = await fetchAllServices()
        } finally {
            this.isLoading = false
        }
    }

    created() {
        this._fetchAllServices()
    }
}
