







import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import AlertBox from '#/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class AlertBoxSubscription extends Vue {}
